@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.cursive {
  font-family: "Open Sans", cursive;
  font-weight: 700;
}

.pros img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

#homeimgid {
  height: calc(100vh - 107px);
}
#aboutimg {
  height: calc(100vh - 107px);
}

